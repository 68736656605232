<template>
  <div>
    <v-row>
      <v-col>
        <!--          <v-row>-->
        <!--            <v-col>-->
        <!--              <v-btn class="float-right mr-5" @click="exportToCSV" v-if="track_report.top50.length > 0">-->
        <!--                Export To CSV-->
        <!--              </v-btn>-->
        <!--            </v-col>-->
        <!--          </v-row>-->
        <v-row>
          <v-col>
            <v-card-title>Summary Stats For Articles</v-card-title>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card-text>
              <v-text-field
                  :value=" all_time_loading ? 'Loading..' : format_numbers(all_time.articles)"
                  label="Total Articles"
                  outlined
                  readonly
              ></v-text-field>
            </v-card-text>
          </v-col>
          <v-col>
            <v-card-text>
              <v-text-field
                  :value=" all_time_loading ? 'Loading..' : format_numbers(track_report.total.views)"
                  label="Total Views"
                  outlined
                  readonly
              ></v-text-field>
            </v-card-text>
          </v-col>
          <v-col>
            <v-card-text>
              <v-text-field
                  :value=" all_time_loading ? 'Loading..' : format_numbers(all_time.reactions)"
                  label="Total Reactions"
                  outlined
                  readonly
              ></v-text-field>
            </v-card-text>
          </v-col>
          <v-col>
            <v-card-text>
              <v-text-field
                  :value=" all_time_loading ? 'Loading..' : format_numbers(all_time.comments)"
                  label="Total Comments"
                  outlined
                  readonly
              ></v-text-field>
            </v-card-text>
          </v-col>
          <v-col>
            <v-card-text>
              <v-text-field
                  :value=" all_time_loading ? 'Loading..' : format_numbers(all_time.shares)"
                  label="Total Shares"
                  outlined
                  readonly
              ></v-text-field>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card-title>Summary Stats For Videos</v-card-title>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card-text>
              <v-text-field
                  :value=" all_time_loading ? 'Loading..' : format_numbers(all_time_videos.videos)"
                  label="Total Videos"
                  outlined
                  readonly
              ></v-text-field>
            </v-card-text>
          </v-col>
          <v-col>
            <v-card-text>
              <v-text-field
                  :value=" all_time_loading ? 'Loading..' : format_numbers(track_report.total.video_views)"
                  label="Total Views"
                  outlined
                  readonly
              ></v-text-field>
            </v-card-text>
          </v-col>
          <v-col>
            <v-card-text>
              <v-text-field
                  :value=" all_time_loading ? 'Loading..' : format_numbers(all_time_videos.reactions)"
                  label="Total Reactions"
                  outlined
                  readonly
              ></v-text-field>
            </v-card-text>
          </v-col>
          <v-col>
            <v-card-text>
              <v-text-field
                  :value=" all_time_loading ? 'Loading..' : format_numbers(all_time_videos.comments)"
                  label="Total Comments"
                  outlined
                  readonly
              ></v-text-field>
            </v-card-text>
          </v-col>
          <v-col>
            <v-card-text>
              <v-text-field
                  :value=" all_time_loading ? 'Loading..' : format_numbers(all_time_videos.shares)"
                  label="Total Shares"
                  outlined
                  readonly
              ></v-text-field>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row>
          <v-col>

          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row class="mb-4">
              <v-col>
                <h5 class="ml-3"> Top 50 Content </h5>
              </v-col>
              <v-col>
                <v-btn class="float-right mr-2" depressed elevation="1" small @click="exportToCSV">Download CSV</v-btn>
              </v-col>
            </v-row>
            <v-card-text>
              <v-data-table
                  :headers="headers"
                  :items="track_report.top50"
                  :items-per-page="10"
                  :loading="track_report_loading"
                  loading-text="Loading... Please wait"
              >
                <template
                    v-slot:item.views="{ item }"
                >
                  {{ item.total_views | number }}
                </template>
                <template
                    v-slot:item.reactions="{ item }"
                >
                  {{ item.reactions | number }}
                </template>
                <template
                    v-slot:item.comments="{ item }"
                >
                  {{ item.comments | number }}
                </template>
                <template
                    v-slot:item.shares="{ item }"
                >
                  {{ item.shares | number }}
                </template>
                <template
                    v-slot:item.action="{ item }"
                >
                  <a v-if="item.type == 'a'" :href="'https://newswav.com/'+item.unique_id" class="btn btn-outline-info" target="_blank">
                    Link
                  </a>
                   <a v-if="item.type == 'v'" :href="'https://newswav.com/video/'+item.unique_id" class="btn btn-outline-info" target="_blank">
                    Link
                  </a>
                </template>
              </v-data-table>
            </v-card-text>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import numeral from 'numeral'

export default {
  name: "TrackingReport",
  props: {
    publisher: {
      type: Object
    },
    dateFrom: {
      type: String
    },
    dateTo: {
      type: String
    }
  },
  data() {
    return {
      headers: [
        {text: 'Title', value: 'title', width: "40%"},
        {text: 'Published Date', value: 'publishedDate'},
        {text: 'Views', value: 'views'},
        {text: 'Total Reactions', value: 'reactions'},
        {text: 'Total Comments', value: 'comments'},
        {text: 'Total Shares', value: 'shares'},
        {text: 'Action', filterable: false, value: 'action'},
      ],
      show: false,
      tab: null
    }
  },
  filters: {
    upperCase(val) {
      return val.toUpperCase()
    },
    number(val) {
      if (val.toString().length > 6) {
        return numeral(val).format('0.0a')
      } else {
        return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }
    }
  },
  computed: {
    track_report() {
      return this.$store.getters.getTracking
    },
    track_report_loading() {
      return this.$store.getters.getReportLoading
    },
    report_link() {
      let name = ''
      this.track_report.forEach(data => {
        name = data.publisher
      })
      let reportName = name + '-' + this.dateFrom + '-' + this.dateTo + '.pdf'
      let url = 'https://newswav-ge.web.app/generate-report/' + this.publisher.id + '/' + this.dateFrom + '/' + this.dateTo
      return 'https://us-central1-firebase-newswav.cloudfunctions.net/renderAsPdf?name=' + reportName + '&url=' + url
    },
    all_time_loading() {
      return this.$store.getters.getAllTimeLoading
    },
    all_time: {
      get() {
        return this.$store.getters.getAllTime
      },
      set(newVal) {
        console.log(newVal)
        return this.$store.getters.getAllTime
      }
    },
    all_time_videos: {
      get() {
        return this.$store.getters.getAllTimeVideos
      },
      set(newVal) {
        console.log(newVal)
        return this.$store.getters.getAllTimeVideos
      }
    }
  },
  methods: {
    format_numbers(val) {
      return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    exportToCSV() {
      let BOM = "\uFEFF";
      let dateFrom = this.formatDate(new Date(this.dateFrom))
      let dateTo = this.formatDate(new Date(this.dateTo))
      let csv = ''
      csv += '\nPublisher Name, Date From, Date To\n'
      csv += `"${this.publisher.name}", ${dateFrom}, ${dateTo} \n\n`
      csv += 'Total Articles, Total Views, Total Reactions, Total Comments, Total Shares\n'
      csv += `"${this.all_time.articles}", ${this.track_report.total.views}, ${this.all_time.reactions}, ${this.all_time.comments}, ${this.all_time.shares},\n`
      csv += 'Total Videos, Total Views, Total Reactions, Total Comments, Total Shares\n'
      csv += `"${this.all_time_videos.videos}", ${this.track_report.total.video_views}, ${this.all_time_videos.reactions}, ${this.all_time_videos.comments}, ${this.all_time_videos.shares},\n\n`
      csv += 'Top 50 Content, \t, \t, \t,\n'
      csv += 'Title, Published Date, Total Views, Total Reactions, Total Comments, Total Shares \n'
      this.track_report.top50.forEach(art => {
        csv += `"${art.title}", ${art.publishedDate}, ${art.views}, ${art.reactions}, ${art.comments}, ${art.shares}, \n`
      })
      let reportName = this.publisher.name + '_' + this.dateFrom + '_' + this.dateTo + '.csv'
      let hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(BOM + csv);
      hiddenElement.target = '_blank';
      hiddenElement.download = reportName;
      hiddenElement.click();
    },
    // generateReport: function () {
    //   const pdfMake = require('pdfmake/build/pdfmake.js');
    //   const pdfFonts = require('pdfmake/build/vfs_fonts.js');
    //   pdfMake.vfs = pdfFonts.pdfMake.vfs;
    //
    //   let name = ''
    //   let doc = {
    //     content : []
    //   }
    //   let article = []
    //   let react = []
    //   this.track_report.forEach(rep => {
    //     name = rep.publisher
    //     rep.top_10_article.forEach(art => {
    //       let temp = [art.title, `${art.view_count}`, `${art.reaction_count}`]
    //       article.push(temp)
    //     })
    //     rep.top_10_reacted_article.forEach(rea => {
    //       let temp = [rea.title, `${rea.view_count}`, `${rea.reaction_count}`]
    //       react.push(temp)
    //     })
    //     doc.content.push({text: 'Publisher Report', style: 'header'})
    //     doc.content.push({
    //       table: {
    //         widths: ['*', '*', '*', '*'],
    //         headerRows: 1,
    //         body: [
    //           [
    //             {text: 'Publisher', fillColor: '#ff7f50'},
    //             {text: 'Language', fillColor: '#ff7f50'},
    //             {text: 'Date From', fillColor: '#ff7f50'},
    //             {text: 'Date To', fillColor: '#ff7f50'}
    //           ],
    //           [rep.publisher, rep.language, this.dateFrom, this.dateTo]
    //         ]
    //       }
    //     })
    //     doc.content.push({
    //       table: {
    //         widths: ['*', '*', '*', '*'],
    //         headerRows: 1,
    //         body: [
    //           [
    //             {text: 'Total Ad Revenue (RM)', fillColor: '#00FA9A'},
    //             {text: 'Total Published On Newswav', fillColor: '#00FA9A'},
    //             {text: 'Total Article Views', fillColor: '#00FA9A'},
    //             {text: 'Total Reaction Count', fillColor: '#00FA9A'}
    //           ],
    //           [rep.total_ad_revenue, rep.c, rep.total_article_views, rep.total_reaction_count]
    //         ]
    //       }
    //     })
    //     doc.content.push({text: 'Top 10 Viewed Article', style: 'header'})
    //     doc.content.push({
    //       table: {
    //         widths: ['*', '*', '*'],
    //         headerRows: 1,
    //         body : [
    //           [
    //             {text:'Title', fillColor: '#F0E68C'},
    //             {text:'View Count', fillColor: '#F0E68C'},
    //             {text:'Reaction Count', fillColor: '#F0E68C'},
    //           ],
    //         ]
    //       }
    //     })
    //     doc.content.push({text: 'Top 10 Reacted Article', style: 'header'})
    //     doc.content.push({
    //       table: {
    //         widths: ['*', '*', '*'],
    //         headerRows: 1,
    //         body : [
    //           [
    //             {text:'Title', fillColor: '#F0E68C'},
    //             {text:'View Count', fillColor: '#F0E68C'},
    //             {text:'Reaction Count', fillColor: '#F0E68C'},
    //           ],
    //         ]
    //       }
    //     })
    //     // doc = {
    //     //   content: [
    //     //     {text: 'Publisher Report', style: 'header'},
    //     //     {
    //     //       table: {
    //     //         widths: ['*', '*', '*', '*'],
    //     //         headerRows: 1,
    //     //         body: [
    //     //             [
    //     //               {text: 'Publisher', fillColor: '#ff7f50'},
    //     //               {text: 'Language', fillColor: '#ff7f50'},
    //     //               {text: 'Date From', fillColor: '#ff7f50'},
    //     //               {text: 'Date To', fillColor: '#ff7f50'}
    //     //             ],
    //     //             [rep.publisher, rep.language, this.dateFrom, this.dateTo]
    //     //         ]
    //     //       }
    //     //     },
    //     //     {
    //     //       table: {
    //     //         widths: ['*', '*', '*', '*'],
    //     //         headerRows: 1,
    //     //         body: [
    //     //           [
    //     //             {text: 'Total Ad Revenue (RM)', fillColor: '#00FA9A'},
    //     //             {text: 'Total Published On Newswav', fillColor: '#00FA9A'},
    //     //             {text: 'Total Article Views', fillColor: '#00FA9A'},
    //     //             {text: 'Total Reaction Count', fillColor: '#00FA9A'}
    //     //           ],
    //     //           [rep.total_ad_revenue, rep.total_published_on_newswav, rep.total_article_views, rep.total_reaction_count]
    //     //         ]
    //     //       }
    //     //     },
    //     //     {text: 'Top 10 Viewed Article', style: 'header'},
    //     //     {
    //     //       table: {
    //     //         widths: ['*', '*', '*'],
    //     //         headerRows: 1,
    //     //         body : [
    //     //             [
    //     //               {text:'Title', fillColor: '#F0E68C'},
    //     //               {text:'View Count', fillColor: '#F0E68C'},
    //     //               {text:'Reaction Count', fillColor: '#F0E68C'},
    //     //             ],
    //     //         ]
    //     //       }
    //     //     },
    //     //     {text: 'Top 10 Reacted Article', style: 'header'},
    //     //     {
    //     //       table: {
    //     //         widths: ['*', '*', '*'],
    //     //         headerRows: 1,
    //     //         body : [
    //     //           [
    //     //             {text:'Title', fillColor: '#F0E68C'},
    //     //             {text:'View Count', fillColor: '#F0E68C'},
    //     //             {text:'Reaction Count', fillColor: '#F0E68C'},
    //     //           ],
    //     //         ]
    //     //       }
    //     //     },
    //     //   ]
    //     // }
    //   })
    //   article.forEach(text =>{
    //     doc.content[4].table.body.push(text)
    //   })
    //   react.forEach(text => {
    //     doc.content[6].table.body.push(text)
    //   })
    //   pdfMake.createPdf(doc).download(`${name}-Report-${this.dateFrom}-${this.dateTo}.pdf`)
    // }
    formatDate(date) {
      let dd = 0
      let mm = 0
      let yy = 0

      dd = date.getDate()
      if (dd < 10) {
        dd = '0' + dd
      }
      mm = date.getMonth() + 1
      if (mm < 10) {
        mm = '0' + mm
      }
      yy = date.getFullYear()
      return yy + '/' + mm + '/' + dd
    }
  }
}
</script>

<style scoped>

</style>
