<template>
  <div>
    <v-row>
      <v-col>
        <TrackPublisher/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TrackPublisher from "./Publisher/TrackPublisher";
export default {
name: "HomeDashboard",
  components: {TrackPublisher}
}
</script>

<style scoped>

</style>